<template>
  <div class="selection-box" ref="dropdown">
    <div
      :class="['selection-box__selected', selectedBoxClass, { 'is-active': isDropdownOpen }]"
      @click="onToggleDropdown"
    >
      <p>{{ localCheckedValue.name }}</p>
      <toko-selection-down-icon v-show="!isDropdownOpen" />
      <toko-selection-up-icon v-show="isDropdownOpen" />
    </div>

    <ul :class="['selection-box__items', { 'align-right': isAlignRight }]" v-show="isDropdownOpen">
      <li
        :class="[
          'selection-box__item',
          { checked: localCheckedValue.value === option.value },
          { disabled: option.disabled }
        ]"
        v-for="option in options"
        :key="option.value"
        @click="onSelected(option)"
      >
        {{ option.name }}
        <toko-tick-icon v-show="localCheckedValue.value === option.value" />
      </li>
    </ul>
  </div>
</template>

<script>
import toggleDropdown from '@/mixins/toggle-dropdown'
const TokoSelectionDownIcon = () => import('@/ui/toko-icons/TokoSelectionDown')
const TokoSelectionUpIcon = () => import('@/ui/toko-icons/TokoSelectionUp')
const TokoTickIcon = () => import('@/ui/toko-icons/TokoTick')

export default {
  name: 'SelectionBox',

  data() {
    return {
      localCheckedValue: null
    }
  },

  mixins: [toggleDropdown],

  props: {
    value: {
      type: Number,
      required: true
    },

    selectedBoxClass: {
      type: String,
      default() {
        return 'is-md'
      },
      required: false
    },

    enabledValues: {
      type: Array,
      required: false
    },

    options: {
      type: Array,
      required: true
    },

    isAlignRight: {
      type: Boolean,
      default: false
    },

    onCheck: {
      type: Function,
      required: false
    }
  },

  components: {
    TokoSelectionDownIcon,
    TokoSelectionUpIcon,
    TokoTickIcon
  },

  methods: {
    onSelected(data) {
      if (this.localCheckedValue.value !== data.value) {
        this.localCheckedValue = data
        this.$emit('input', data.value)

        if (this.onCheck) this.onCheck()
      }

      this.onCloseDropdown()
    }
  },

  created() {
    const selectedOption = this.options.filter(option => option.value === this.value)
    this.localCheckedValue = { ...selectedOption[0] }
  },

  watch: {
    enabledValues: {
      handler(newValue) {
        if (newValue.indexOf(this.localCheckedValue.value) !== -1) return

        const selectedOption = this.options.filter(option => !option.disabled)
        this.localCheckedValue = { ...selectedOption[0] }
        this.$emit('input', this.localCheckedValue.value)
      },
      deep: true
    }
  }
}
</script>
