export default {
  data() {
    return {
      isDropdownOpen: false
    }
  },

  methods: {
    onCloseDropdown() {
      this.isDropdownOpen = false
    },

    onToggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },

    closeDropdownTrigger(e) {
      const el = this.$refs.dropdown
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.onCloseDropdown()
      }
    }
  },

  created() {
    document.addEventListener('click', this.closeDropdownTrigger)
  },

  destroyed() {
    document.removeEventListener('click', this.closeDropdownTrigger)
  }
}
